@import "./fonts.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply font-outfit font-light bg-january-snow;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply font-departure-mono;
  }
}

@layer components {
  .nav-link {
    @apply font-departure-mono text-winter-night hover:text-mitit-red transition-colors duration-200;
  }

  .nav-button {
    @apply font-departure-mono px-4 py-2 rounded-full transition-all duration-200 transform hover:scale-105 active:scale-95;
  }

  .primary-button {
    @apply font-departure-mono px-4 py-2 rounded-full 
      bg-mitit-red text-january-snow 
      hover:bg-[#A33351] hover:scale-105 
      active:bg-[#8F2D47]
      transition-all duration-200;
  }
  
  .secondary-button {
    @apply font-departure-mono px-4 py-2 rounded-full 
      bg-cyberpunk-blue text-january-snow 
      hover:bg-[#4B4D6B] hover:scale-105 
      active:bg-[#424460]
      transition-all duration-200;
  }
}

@layer utilities {
  .perspective-1000 {
    perspective: 1000px;
  }

  .transform-style-3d {
    transform-style: preserve-3d;
  }

  .backface-hidden {
    backface-visibility: hidden;
  }

  .rotate-y-180 {
    transform: rotateY(180deg);
  }
}

.overflow-x-clip {
  overflow-x: clip;
}

.fade-in {
  opacity: 0;
  transform: translateY(1rem);
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}

.fade-in.visible {
  opacity: 1;
  transform: translateY(0);
}

.card-reveal {
  opacity: 0;
  transform: rotateY(180deg);
  transition: opacity 0.4s ease-out, transform 0.4s ease-out;
}

.card-reveal.visible {
  opacity: 1;
  transform: rotateY(0);
}

@layer utilities {
  .perspective-1000 {
    perspective: 1000px;
  }
}

/* Custom checkbox styles */
input {
  @apply transition-all duration-200;
}

input::placeholder {
  @apply text-winter-night/40;
}

.form-shadow {
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
              0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

input[type="checkbox"] {
  @apply rounded border-winter-night/20 text-mitit-red focus:ring-mitit-red;
}

/* Custom form focus states */
/* Remove or replace these styles */
input:focus {
  @apply outline-none ring-0 ring-offset-0 border-winter-night;
}

/* Add these styles */
input, select, textarea {
  @apply focus:outline-none focus:ring-0 focus:ring-offset-0;
}

@keyframes fall {
  0% {
    transform: translateY(-10vh) rotate(0deg);
  }
  100% {
    transform: translateY(110vh) rotate(360deg);
  }
}
