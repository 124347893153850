@font-face {
  font-family: 'Outfit';
  src: url('../../public/fonts/Outfit-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Outfit';
  src: url('../../public/fonts/Outfit-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Outfit';
  src: url('../../public/fonts/Outfit-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Outfit';
  src: url('../../public/fonts/Outfit-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Outfit';
  src: url('../../public/fonts/Outfit-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Outfit';
  src: url('../../public/fonts/Outfit-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Outfit';
  src: url('../../public/fonts/Outfit-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Outfit';
  src: url('../../public/fonts/Outfit-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Outfit';
  src: url('../../public/fonts/Outfit-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}
  
@font-face {
  font-family: 'Source Code Pro';
  src: url('../../public/fonts/SourceCodePro-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
  
@font-face {
  font-family: 'Departure Mono';
  src: url('../../public/fonts/DepartureMono-Regular.woff2') format('woff2'),
        url('../../public/fonts/DepartureMono-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}